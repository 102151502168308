import { makeStyles } from "@material-ui/styles";
import banner from "assets/images/login-banner.jpg";

// login view styling
export const loginViewStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    height: "100vh",
    backgroundImage: `url(${banner})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  grid: {
    height: "100%",
  },
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
  },
  logoImage: {
    marginLeft: 32,
  },
  contentBody: {
    opacity: 0.9,
    position: "absolute",
    right: "1%",
    top: "5%",
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      width: "30%",
    },
    [theme.breakpoints.down("md")]: {
      width: "40%",
    },
    [theme.breakpoints.down("xs")]: {
      top: "6%",
      width: "95%",
    },
  },
  form: {
    textAlign: "center",
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    flexBasis: 700,
  },
  textField: {
    marginTop: 16,
  },
  signInButton: {
    margin: "8px 0",
    width: "50%",
    padding: "5px 20px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    height:"2.5em"
  },
  wrapper: {
    margin: "10",
    position: "relative",
  },
  buttonProgress: {
    color: "#363636",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  forgetPassword: {
    width: "100%",
    textAlign: "center",
  },
}));
