const homeReducer = (
  state = {
    notification: {},
    loading1: true,
    annotationData: "",
    resetForm: false,
    patientListLoading: false,
    patientList: [],
    showReport: false,
    patientDetails: {
      loading: false,
      selected: false,
      diagnosis: [],
      annotations: {},
      fundusImages: {},
      formData: {},
    },
    globalChangeHandler: [],
    triggerGlobalSave: null,
    savedData: [],
  },
  action
) => {
  switch (action.type) {
    case "GET_PATIENTS_PENDING": {
      return {
        ...state,
        patientDetails: {
          diagnosis: [],
        },
        patientListLoading: true,
        globalChangeHandler: [],
        triggerGlobalSave: null,
        savedData: [],
      };
    }
    case "GET_PATIENTS_FULFILLED": {
      return {
        ...state,
        patientListLoading: false,
        patientList: action.payload.data,
      };
    }
    case "GET_PATIENTS_REJECTED": {
      return {
        ...state,
        patientListLoading: false,
      };
    }
    case "GET_ALL_DETAILS_PENDING": {
      return {
        ...state,
        patientDetails: {
          loading: true,
          formData: {
            diagnosisTable: {},
          },
          ...state.patientDetails,
        },
      };
    }
    case "CLEAR_ALL_DETAILS": {
      return {
        ...state,
        patientDetails: {
          diagnosis: [],
        },
      };
    }
    case "SET_DIAGNOSIS_TABLE_DATA": {
      return {
        ...state,
        patientDetails: {
          ...state.patientDetails,
          formData: {
            ...state.patientDetails.formData,
            diagnosisTable: {
              [action.payload.laterality]: {
                ...action.payload.value,
                ...state.patientDetails.formData.diagnosisTable[
                  action.payload.laterality
                ],
              },
              ...state.patientDetails.formData.diagnosisTable,
            },
          },
        },
      };
    }
    case "SET_QUALITY_FORM_DATA": {
      return {
        ...state,
        patientDetails: {
          ...state.patientDetails,
          formData: {
            ...state.patientDetails.formData,
            ...action.payload.formData,
          },
        },
      };
    }
    case "GET_ALL_DETAILS_FULFILLED": {
      return {
        ...state,
        patientDetails: {
          loading: false,
          selected: true,
          unlocked: true,
          ...state.patientDetails,
          ...action.payload.data,
        },
      };
    }
    case "SET_UNLOCK_FORM": {
      return {
        ...state,
        patientDetails: {
          ...state.patientDetails,
          unlocked: action.payload,
        },
      };
    }
    case "GET_ALL_IMAGES_FULFILLED": {
      return {
        ...state,
        patientDetails: {
          ...state.patientDetails,
          fundusImages: action.payload.data,
        },
      };
    }
    case "GET_S3_URL_PENDING": {
      return {
        ...state,
        loading1: true,
      };
    }
    case "GET_S3_URL_FULFILLED": {
      return {
        ...state,
        loading1: false,
        patientDetails: {
          ...state.patientDetails,
          fundusImages: {
            left: action.payload.data.filter(
              (img) => img.laterality === "left"
            ),
            right: action.payload.data.filter(
              (img) => img.laterality === "right"
            ),
          },
        },
      };
    }
    case "POST_ANNOTATION_PENDING":
      return {
        ...state,
        resetForm: true,
        image: state.nextImage,
        imageKey: state.nextImageKey,
      };
    case "POST_ANNOTATION_FULFILLED":
      return {
        ...state,
        resetForm: true,
        annotationData: action.payload.data,
      };
    case "SUBMIT_CLIENT_DIAGNOSIS": {
      return {
        ...state,
        patientDetails: {
          ...state.patientDetails,
          diagnosis: [...state.patientDetails.diagnosis, action.payload],
        },
      };
    }
    case "SUBMIT_CLIENT_PRESCRIPTION": {
      return {
        ...state,
        patientDetails: {
          ...state.patientDetails,
          prescription: action.payload,
        },
      };
    }
    case "SUBMIT_FUNDUS_ANNOTATIONS": {
      return {
        ...state,
        patientDetails: {
          ...state.patientDetails,
          annotations: {
            ...state.patientDetails.annotations,
            [action.payload.laterality]: action.payload.annotations,
          },
        },
      };
    }
    case "GET_LEFT_ANNOTATIONS_FULFILLED": {
      return {
        ...state,
        patientDetails: {
          ...state.patientDetails,
          diagnosis: {
            ...state.patientDetails.diagnosis,
            left: action.payload.data,
          },
        },
      };
    }
    case "GET_RIGHT_ANNOTATIONS_FULFILLED": {
      return {
        ...state,
        patientDetails: {
          ...state.patientDetails,
          diagnosis: {
            ...state.patientDetails.diagnosis,
            right: action.payload.data,
          },
        },
      };
    }
    case "SUBMIT_ADVICE": {
      return {
        ...state,
        patientDetails: {
          ...state.patientDetails,
          advice: action.payload,
        },
      };
    }
    case "SUBMIT_REVISIT_DAYS":
      return {
        ...state,
        patientDetails: {
          ...state.patientDetails,
          visitDays: action.payload,
        },
      };
    case "SHOW_NOTIFICATION": {
      return {
        ...state,
        notification: action.payload,
      };
    }
    case "SHOW_REPORT": {
      return {
        ...state,
        showReport: action.payload,
      };
    }
    case "GLOBAL_CHANGES": {
      return {
        ...state,
        globalChangeHandler: [...state.globalChangeHandler, action.payload],
      };
    }
    case "TRIGGER_GLOBAL_SAVE": {
      return {
        ...state,
        triggerGlobalSave: new Date(),
        savedData: [...state.savedData, ...state.globalChangeHandler],
      };
    }
    case "AFTER_GLOBAL_SAVE": {
      return {
        ...state,
        globalChangeHandler: [],
      };
    }
    default:
      return state;
  }
};

export default homeReducer;
