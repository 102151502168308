import { createMuiTheme } from "@material-ui/core";

const getCurrColour = () => {
  const theme = !localStorage.getItem("theme")
    ? "orange"
    : localStorage.getItem("theme");
  if (theme === "orange" || theme === "default") return "#f77200";
  else if (theme === "purple") return "#800080";
  else if (theme === "green") return "#98bc62";
};

export default createMuiTheme({
  palette: {
    primary: {
      main: getCurrColour(),
    },
    secondary: {
      main: "#fff",
    },
    warning: {
      main: "rgb(255,0,0)",
    },
  },
});
